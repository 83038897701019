<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12" lg="12" xl="12">
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.reviewsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="passengerReviewsHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="passengerReviews"
          :search="searchString"
          class="elevation-1 cursor-pointer"
          :items-per-page="10"
          @click:row="openTransfer"
          multi-sort>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.passengerReviews') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>
              </template>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import router from '@/router';
import { passengerReviewsHeaders } from '@/mixins/data-table-headers';
import { formatDateTime } from '@/utils/formatDate';

export default {
  name: 'DashboardPage',
  props: [],
  components: {},
  data: () => ({
    passengerReviews: [],
    searchString: '',
  }),
  created() {
    this.getDashboardData();
  },
  computed: {
    passengerReviewsHeaders() {
      return passengerReviewsHeaders(i18n);
    },
  },
  methods: {
    async getDashboardData() {
      await this.$store.dispatch('passengerReviews/getAllReviews').then((res) => {
        this.passengerReviews = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);

          return item;
        });
      });
    },
    openTransfer(item) {
      // Resolve the URL from the router
      const routeData = router.resolve({
        name: this.$store.getters['auth/role'] == 1 ? 'TabularView' : 'CalendarView',
        query: { driving_id: item.driving.id },
      });

      // Open a new tab with the resolved URL
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
